import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contact_45successJtmrpHVWIjMeta } from "/vercel/path0/pages/contact-success.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as _91item_93GHVunr73gyMeta } from "/vercel/path0/pages/events/[item].vue?macro=true";
import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as meetingform_45successjXA57QPQ5KMeta } from "/vercel/path0/pages/meetingform-success.vue?macro=true";
import { default as meetingformyqklQBmRwxMeta } from "/vercel/path0/pages/meetingform.vue?macro=true";
import { default as _91item_93Z6s9hH0QMEMeta } from "/vercel/path0/pages/meetingpackages/[item].vue?macro=true";
import { default as indexdbYcUSRZncMeta } from "/vercel/path0/pages/meetingpackages/index.vue?macro=true";
import { default as meetingroomsePjiPPEGcdMeta } from "/vercel/path0/pages/meetingrooms.vue?macro=true";
import { default as _91item_936E40VvcyMaMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as roomstL5659IyVgMeta } from "/vercel/path0/pages/rooms.vue?macro=true";
import { default as shopbVVgDM89cGMeta } from "/vercel/path0/pages/shop.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___es",
    path: "/es/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "contact-success___en",
    path: "/contact-success",
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact-success___nl",
    path: "/nl/contact-success",
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact-success___de",
    path: "/de/contact-success",
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact-success___es",
    path: "/es/contact-success",
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact-success___fr",
    path: "/fr/contact-success",
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___es",
    path: "/es/contacto",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "events-item___en",
    path: "/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events-item___nl",
    path: "/nl/evenementen/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events-item___de",
    path: "/de/veranstaltungen/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events-item___es",
    path: "/es/eventos/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events-item___fr",
    path: "/fr/evenements/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue").then(m => m.default || m)
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___nl",
    path: "/nl/evenementen",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___de",
    path: "/de/veranstaltungen",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___es",
    path: "/es/eventos",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___fr",
    path: "/fr/evenements",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___nl",
    path: "/nl/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/de/haufig-gestellte-fragen",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___es",
    path: "/es/preguntas-frecuentes",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr",
    path: "/fr/questions-frequemment-posees",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___de",
    path: "/de/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___es",
    path: "/es/galeria",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___fr",
    path: "/fr/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "highlights___en",
    path: "/location",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___nl",
    path: "/nl/locatie",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___de",
    path: "/de/location",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___es",
    path: "/es/ubicacion",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___fr",
    path: "/fr/emplacement",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingform-success___en",
    path: "/meeting-success",
    component: () => import("/vercel/path0/pages/meetingform-success.vue").then(m => m.default || m)
  },
  {
    name: "meetingform-success___nl",
    path: "/nl/meeting-success",
    component: () => import("/vercel/path0/pages/meetingform-success.vue").then(m => m.default || m)
  },
  {
    name: "meetingform-success___de",
    path: "/de/meeting-success",
    component: () => import("/vercel/path0/pages/meetingform-success.vue").then(m => m.default || m)
  },
  {
    name: "meetingform-success___es",
    path: "/es/meeting-success",
    component: () => import("/vercel/path0/pages/meetingform-success.vue").then(m => m.default || m)
  },
  {
    name: "meetingform-success___fr",
    path: "/fr/meeting-success",
    component: () => import("/vercel/path0/pages/meetingform-success.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___en",
    path: "/meeting-form",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___nl",
    path: "/nl/vergaderformulier",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___de",
    path: "/de/tagungsformular",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___es",
    path: "/es/formulario-de-reunion",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___fr",
    path: "/fr/formulaire-de-reunion",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___en",
    path: "/meeting-packages/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___nl",
    path: "/nl/vergaderarrangementen/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___de",
    path: "/de/tagungspakete/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___es",
    path: "/es/paquetes-de-reunion/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___fr",
    path: "/fr/forfaits-de-reunion/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___en",
    path: "/meeting-packages",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___nl",
    path: "/nl/vergaderarrangementen",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___de",
    path: "/de/tagungspakete",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___es",
    path: "/es/paquetes-de-reunion",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___fr",
    path: "/fr/forfaits-de-reunion",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___en",
    path: "/meeting-rooms",
    component: () => import("/vercel/path0/pages/meetingrooms.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___nl",
    path: "/nl/vergaderzalen",
    component: () => import("/vercel/path0/pages/meetingrooms.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___de",
    path: "/de/tagungsraume",
    component: () => import("/vercel/path0/pages/meetingrooms.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___es",
    path: "/es/salas-de-reuniones",
    component: () => import("/vercel/path0/pages/meetingrooms.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___fr",
    path: "/fr/salles-de-reunions",
    component: () => import("/vercel/path0/pages/meetingrooms.vue").then(m => m.default || m)
  },
  {
    name: "news-item___en",
    path: "/news/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: "news-item___nl",
    path: "/nl/nieuws/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: "news-item___de",
    path: "/de/nachrichten/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: "news-item___es",
    path: "/es/noticias/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: "news-item___fr",
    path: "/fr/actualites/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/news",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___nl",
    path: "/nl/nieuws",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___de",
    path: "/de/nachrichten",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___es",
    path: "/es/noticias",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___fr",
    path: "/fr/actualites",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "packages-item___en",
    path: "/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___nl",
    path: "/nl/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___de",
    path: "/de/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___es",
    path: "/es/arreglos/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___fr",
    path: "/fr/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages___en",
    path: "/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___nl",
    path: "/nl/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___de",
    path: "/de/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___es",
    path: "/es/arreglos",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___fr",
    path: "/fr/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "reviews___en",
    path: "/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___nl",
    path: "/nl/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___de",
    path: "/de/rezensionen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___es",
    path: "/es/resenas",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___fr",
    path: "/fr/revues",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "rooms___en",
    path: "/rooms",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___nl",
    path: "/nl/kamers",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___de",
    path: "/de/zimmer",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___es",
    path: "/es/habitaciones",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___fr",
    path: "/fr/chambres",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "shop___en",
    path: "/shop",
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "shop___nl",
    path: "/nl/shop",
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "shop___de",
    path: "/de/shop",
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "shop___es",
    path: "/es/shop",
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "shop___fr",
    path: "/fr/shop",
    component: () => import("/vercel/path0/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___es",
    path: "/es/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]